import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { FilterFunction } from "models/dto/ZoomiLxp/Utilities/Enumerations/FilterFunction";
import { IFilterCriterion } from "models/dto/ZoomiLxp/Models/Query/IFilterCriterion";
import { acceptedMimeTypes } from "constants/constants";
import { ICroppedFileOptions } from "components/partial/image-cropper/image-cropper.helper";
import { FileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/FileTypes";
import { UploadFileTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/UploadFileTypes";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import { contentDurationValidator, contentNameValidator } from "helpers/validator.helper";
import * as Yup from "yup";
import { FormikErrors, FormikValues } from "formik";
import isEmpty from "lodash/isEmpty";
import { ISegmentDisplayNameModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentDisplayNameModel";
import { ISegmentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentModel";
import { ISegmentFormModel } from "store/content/content-store";
import { ToasterStore } from "store/toaster-store/toaster-store";

export enum ContentProfileType {
	ExistingContent = "EXISTING_CONTENT",
	Html = "HTML",
	Scorm = "SCORM",
}
export interface ContentUpdateType extends IContentModel {
	segments: ISegmentFormModel[];
	chapterId?: number;
}

export const getIsSegmentNameChangeDisabled = (segment: ISegmentFormModel) => {
	const aiName = segment.aiName ? segment.aiName.toLowerCase() : "";
	const manualName = segment.inputName ? segment.inputName.toLowerCase() : "";
	return !isEmpty(aiName) && aiName === manualName;
};

export const getSegmentName = (segment: ISegmentModel) => {
	if (segment.displayName && segment.displayName?.length) {
		return segment.displayName;
	}
	if (segment.aiName && segment.aiName.length) {
		return segment.aiName;
	}
	return segment.name;
};

export const segmentsConverter = (data: ISegmentFormModel[]) => {
	const segmentsDisplayNames: ISegmentDisplayNameModel[] = data.map((item: ISegmentFormModel) => {
		return {
			segmentId: item.id,
			name: item.inputName ?? "",
		};
	});

	return segmentsDisplayNames;
};

export const getAcceptTypes = (contentProfileType: string): string => {
	if (contentProfileType === ContentProfileType.Html) {
		return acceptedMimeTypes.html;
	} else if (contentProfileType === ContentProfileType.Scorm) {
		return acceptedMimeTypes.scorm;
	}
	return acceptedMimeTypes.complex;
};

export const getTooltip = (contentType: ContentTypes, contentProfileType?: string): string => {
	if (contentProfileType === ContentProfileType.Scorm || contentType === ContentTypes.Scorm) {
		return "Shareable Content Object Reference Model is an international standard for e-courses.";
	}
	if (
		contentProfileType === ContentProfileType.Html ||
		contentType === ContentTypes.Html ||
		contentType === ContentTypes.Mhtml
	) {
		return "You can upload zipped HTML or MHTML";
	}
	if (contentProfileType === ContentProfileType.ExistingContent) {
		return "You can add any public content uploaded by another creator into your course. In case if creator removes or makes this content private it will be deactivated in your course.";
	}
	return "You can upload video, audio, *.pdf, *.doc, *.pptx";
};

export const getProfileTitle = (contentProfileType: string): string => {
	if (contentProfileType === ContentProfileType.Scorm) {
		return "Upload SCORM course";
	}
	if (contentProfileType === ContentProfileType.Html) {
		return "Upload archived HTML";
	}
	if (contentProfileType === ContentProfileType.ExistingContent) {
		return "Add content from Zoomi";
	}
	return "Upload content file";
};

export const initParams: IQueryParams = {
	queryString: "",
	take: 100,
	skip: 0,
	filterCriteria: [
		{
			propertyNames: ["isPublic"],
			function: FilterFunction.IsTrue,
			argument: true,
		} as IFilterCriterion,
		{
			propertyNames: ["isActive"],
			function: FilterFunction.IsTrue,
			argument: true,
		} as IFilterCriterion,
		{
			propertyNames: ["ParentContentId"],
			function: FilterFunction.IsNull,
			argument: true,
		} as IFilterCriterion,
	],
	sortCriteria: [
		{
			order: 1,
			propertyName: "title",
			direction: 0,
		},
	],
};

export const fileCroppingOptions: ICroppedFileOptions = {
	imageType: FileTypes.Thumbnail,
	uploadFileType: UploadFileTypes.Content,
};

export const getContentProfileSchema = (isRefContent?: boolean) =>
	Yup.object().shape({
		name: contentNameValidator,
		manualDuration: contentDurationValidator,
		fileContent: isRefContent
			? Yup.object().notRequired()
			: Yup.object().required("You need to upload the content.").nullable(),
		chapterId: Yup.number().notRequired().nullable(),
	});

export const contentSaveHandler =
	(
		validateForm: (values?: ContentUpdateType) => Promise<FormikErrors<FormikValues>>,
		values: ContentUpdateType,
		setErrors: (errors: FormikErrors<FormikValues>) => void,
		onSave: (data: ContentUpdateType) => Promise<void>,
		toasterStore?: ToasterStore
	) =>
	async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		const errors = await validateForm();
		if (isEmpty(errors)) {
			await onSave(values);
		} else {
			setErrors(errors);
			toasterStore?.showErrorMessage("Please check is form filled correctly.");
			console.log("Saving error:", errors);
		}
		await validateForm();
	};
