import { ICompletionMessageModel } from "models/dto/ZoomiLxp/Models/Assessments/ICompletionMessageModel";
import * as Yup from "yup";
import YupPassword from "yup-password";
import { MAX_EMAILS, MAX_SIMPLE_TEXT } from "constants/constants";
import { IOptionsItem } from "./select.helper";
import size from "lodash/size";
import { regularExpr } from "constants/regular";
import { infoMessages } from "constants/messages";
import { ToasterStore } from "store/toaster-store/toaster-store";

YupPassword(Yup);

Yup.addMethod<Yup.ArraySchema<any>>(
	Yup.array,
	"unique",
	function (message, mapper = <T1>(value: T1, index: number, list: T1[]): any => value) {
		return this.test("unique", message, function (list: any[] | undefined): boolean {
			if (list) {
				return list.length === new Set(list.map(mapper)).size;
			}
			return true;
		});
	}
);

export const urlRegex = /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)[^\s]+)/g;

export const urlValidator = Yup.string().url("Enter correct link").required(infoMessages.requiredField);

export const urlValidatorQuickSight = (maxLength: number = 1000) =>
	Yup.string()
		.required(infoMessages.requiredField)
		.url("Enter correct link")
		.max(maxLength, `The field length must be less than ${maxLength} symbols.`)
		.matches(/quicksight.aws.amazon.com/, "Only QuickSight urls are allowed");

export const simpleTextValidator = (matchErrorText: string, maxLengthErrorText: string, useDigits?: boolean) =>
	Yup.string()
		.required(infoMessages.requiredField)
		.max(256, maxLengthErrorText)
		.matches(
			useDigits
				? /^[a-zA-Z\d]{1,}(?:(-| |- | - |.|. |'|`|&| & | [|] | [/])[a-zA-Z\d]+){0,}(?:([a-zA-Z]*))$/
				: /^[a-zA-Z]{1,}(?:(-| |- | - |.|. |'|`|&| & | [|] | [/])[a-zA-Z]+){0,}(?:([a-zA-Z]*))$/,
			matchErrorText
		);

export const simpleTextNotReqValidator = (matchErrorText: string, maxLengthErrorText: string, useDigits?: boolean) =>
	Yup.string()
		.max(256, maxLengthErrorText)
		.matches(
			useDigits
				? /^[a-zA-Z\d]{1,}(?:(-| |- | - |.|. |'|`|&| & | [|] | [/] )[a-zA-Z\d]+){0,}(?:([a-zA-Z]*))$/
				: /^[a-zA-Z]{1,}(?:(-| |- | - |.|. |'|`|&| & | [|] | [/])[a-zA-Z]+){0,}(?:([a-zA-Z]*))$/,
			matchErrorText
		);

export const lightValidationSchema = (matchErrorText: string, maxLengthErrorText: string, maxLength: number = 256) =>
	Yup.string()
		.required(infoMessages.requiredField)
		.max(maxLength, maxLengthErrorText)
		.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*)$/, matchErrorText);

export const lightNotRequiredValidationSchema = (matchErrorText: string, maxLengthErrorText: string) =>
	Yup.string()
		.max(256, maxLengthErrorText)
		.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*)$/, matchErrorText);

export const tabNameValidator = Yup.string()
	.required(infoMessages.requiredField)
	.min(2, "Tab name length must be more than 2 symbols.")
	.max(50, "Tab name length must be less than 50 symbols.")
	.matches(
		/^([a-zA-Z]|\d){1,}(?:(-|| ||'||`)([a-zA-Z]|\d)+){0,2}$/,
		"'Tab Name' can not contain special symbols (except for the space and dash characters)"
	);

export const customNameValidator = (
	matchErrorText: string,
	minLengthErrorText: string,
	maxLengthErrorText: string,
	maxLength: number = 256
) =>
	Yup.string()
		.required(infoMessages.requiredField)
		.min(2, minLengthErrorText)
		.max(maxLength, maxLengthErrorText)
		.matches(
			/^[a-zA-Z\u00B0\u00BA\u00B5\u00C0-\u00FF]{1,}(?:(-|| ||'||`)[a-zA-Z\u00B0\u00BA\u00B5\u00C0-\u00FF]+){0,2}$/,
			matchErrorText
		);

export const companyNameValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(256, "The Company name length must be less than 256 symbols.")
	.matches(
		/^[a-zA-Z]{1,}(?:(-|| ||- || - ||.||. ||'||`)[a-zA-Z]+)(?:(.)){0,}$/,
		"'Company Name' can not contain special symbols (except for the space, dot and dash characters)"
	);

export const contentNameValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(500, "The content name length must be less than 500 symbols.")
	.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*)$/, "Please check if content name filled correctly.");

export const contentDurationValidator = Yup.number()
	.required(infoMessages.requiredField);

export const titleValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(256, "The field length must be less than 256 symbols.")
	.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*)$/, "Please check if field filled correctly.");

export const descriptionValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(10000, "The description length must be less than 10000 symbols.")
	.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*|\n)$/gm, "Please check if the field filled correctly.");

export const fieldValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(10000, "The field length must be less than 10000 symbols.")
	.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*|\n)$/gm, "Please check if the field filled correctly.");

export const domainValidator = Yup.string()
	.required(infoMessages.requiredField)
	.matches(
		/^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/,
		"Provided domain has to look like '@domain.com' or '@company.net' and etc."
	);

export const validationCodeCheckSchema = Yup.object().shape({
	authCode: Yup.number().typeError("You must specify number").required("OTP is required"),
});
export const maxLengthValidator = (maxLength: number = 500) =>
	Yup.string()
		.required(infoMessages.requiredField)
		.min(1, "The field length cannot be less than 1")
		.max(maxLength, `The field length must be less than ${maxLength} symbols.`);

export const nameValidator = maxLengthValidator(500);

export const fullNameValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(256, "The length of the full name must not exceed 256 characters")
	.matches(
		/^[a-zA-Z\u00B0\u00BA\u00B5\u00C0-\u00FF]{2,}(?: [a-zA-Z\u00B0\u00BA\u00B5\u00C0-\u00FF]+){0,2}$/,
		"Please enter valid full name"
	);

export const emailValidator = Yup.string()
	.email("Invalid email")
	.max(256, "The length of the full name must not exceed 256 characters")
	.matches(/^[^!@#$%^&*)(+=._-].+$/, "Email should not start with a special character")
	.required(infoMessages.requiredField)
	.nullable();

export const emailValidatorEasy = Yup.string().email("Invalid email").required(infoMessages.requiredField);

export const passwordValidator = Yup.string()
	.required(infoMessages.requiredField)
	.password()
	.min(8, "The password must be at least 8 characters long")
	.max(256, "The length of the password must not exceed 256 characters")
	.minLowercase(1, "Password must contain one lowercase character")
	.minUppercase(1, "Password must contain one uppercase character")
	.minNumbers(1, "Password must contain one number character")
	.minSymbols(1, "Password must contain special case character");

export const passwordValidatorEasy = Yup.string().required(infoMessages.requiredField);

export const passwordConfirmationValidator = Yup.string()
	.required(infoMessages.requiredField)
	.password()
	.min(8, "The password must be at least 8 characters long")
	.max(256, "The length of the password must not exceed 256 characters")
	.minLowercase(1, "Password must contain one lowercase character")
	.minUppercase(1, "Password must contain one uppercase character")
	.minNumbers(1, "Password must contain one number character")
	.minSymbols(1, "Password must contain special case character")
	.oneOf([Yup.ref("password"), null], "Passwords must match");

export const acceptValidator = Yup.bool().oneOf([true], "Accept Terms & Conditions is required");

export const expertisesValidator = Yup.array()
	.required("Areas of expertise is required field")
	.min(1, "The number of areas of expertise cannot be less than 1");

export const percentValidator = Yup.number().required("Required").min(0, "Invalid value").max(100, "Invalid value");

export const quizQuestionsValidator = Yup.array().of(
	Yup.object().shape({
		name: Yup.string()
			.required(infoMessages.requiredField)
			.max(5000, "The question name length must be less than 5000 symbols.")
			.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*|\n)$/gm, "Please check if question name filled correctly."),
		weight: Yup.number().required("Required"),
		rightAnswerNumber: Yup.number()
			.nullable()
			.test("check Number", "The field can have no more than one number after the dot", (value) => {
				if (!value && value !== 0) return false;
				return Number(value) === Number((Math.round(value * 10) / 10).toFixed(1));
			}),
		allowedRetryCount: Yup.number().min(1),
		answerOptions: Yup.array().of(
			Yup.object().shape({
				name: maxLengthValidator(10000),
			})
		),
	})
);

export const CourseProfileSchema = Yup.object().shape({
	title: lightValidationSchema(
		"Please check if course title filled correctly.",
		"The course title length must be less than 500 symbols.",
		500
	),
	description: descriptionValidator.nullable(),
	thumbnail: Yup.object().nullable().required("Thumbnail is required"),
	source: lightNotRequiredValidationSchema(
		"'Source' can not contain special symbols (except for the space and dash characters)",
		"The Source name length must be less than 256 symbols."
	).nullable(),
	subjects: Yup.array().min(1, "At least one Subject is required").required("At least one Subject is required"),
	isFreeCourse: Yup.boolean(),
	price: Yup.number()
		.typeError("You must specify a number")
		.when({
			is: (price: number) => price > 0,
			then: Yup.number()
				.min(50, "The price should be 0 (for free course) or higher than 50 cents.")
				.required(infoMessages.requiredField),
		}),
	lockNextChapterPercent: Yup.number().nullable().min(0, "Invalid value").max(100, "Invalid value"),
	additionalFields: Yup.object().shape({
		courseTitle: lightNotRequiredValidationSchema(
			"'Course' can not contain special symbols (except for the space and dash characters)",
			"The Course name length must be less than 256 symbols."
		).nullable(),
		creator: Yup.object().nullable().required(infoMessages.requiredField),
	}),
});

export const signupSchema = Yup.object().shape({
	firstName: customNameValidator(
		"'First Name' can not contain special symbols (except for the space and dash characters)",
		"The first name length must be more than 2 symbols.",
		"The first name length must be less than 256 symbols."
	).nullable(),
	lastName: customNameValidator(
		"'Last Name' can not contain special symbols (except for the space and dash characters) ",
		"The last name length must be more than 2 symbols.",
		"The last name length must be less than 256 symbols."
	).nullable(),
	jobTitle: customNameValidator(
		"'Job Title' can not contain special symbols (except for the space and dash characters)",
		"The job title length must be more than 2 symbols.",
		"The job title length must be less than 256 symbols."
	).nullable(),
	email: emailValidator,
	password: passwordValidator,
	passwordConfirmation: passwordConfirmationValidator,
	acceptTerms: acceptValidator,
});

const getStripeSecretValidator = (isEndpointSecret?: boolean) => {
	return Yup.string()
		.required(infoMessages.requiredField)
		.when({
			is: (secret: string) => !secret || !secret.length,
			then: Yup.string().max(
				256,
				`The Stripe ${isEndpointSecret ? "Endpoint Secret" : "Secret key"} length must be less than 256 symbols.`
			),
		})
		.when({
			is: () => isEndpointSecret,
			then: Yup.string().test("Prefix test", "Endpoint secret should starts with 'whsec_' prefix", (secret) => {
				return secret?.startsWith("whsec_") ? true : false;
			}),
		});
};

export const BillingSettingsSchema = Yup.object().shape({
	stripeEndpointSecret: getStripeSecretValidator(true),
	stripeSecretKey: getStripeSecretValidator(),
});

export const questionCompletionMessagesValidator = Yup.string()
	.required(infoMessages.requiredField)
	.max(256, "Message for correct/wrong answer length must be less than 256 symbols.");

export const quizCompletionMessagesValidator = Yup.array()
	.of(
		Yup.object().shape({
			text: nameValidator,
			percent: percentValidator,
		})
	)
	.unique(
		"Pass score cannot be the same for two or more completion messages",
		(e: ICompletionMessageModel) => e.percent
	);

export const validateEmail = (email: string) => {
	if (!email) return false;
	return regularExpr.email.test(email);
};

export const carouselNameValidator = Yup.string()
	.required(infoMessages.requiredField)
	.min(1, "The field length cannot be less than 1")
	.max(50, "The field length must be less than 50 symbols.")
	.matches(
		/^[a-zA-Z\d]+[a-zA-Z]{1,}(?:(-| |- | - |.|. |'|`|&| & | [|] | [/])[a-zA-Z\d]+){0,}(?:(.))$/,
		"Please check if carousel name filled correctly."
	);

export const subjectValidator = Yup.string()
	.required(infoMessages.requiredField)
	.min(1, "The field length cannot be less than 1")
	.max(500, "The field length must be less than 500 symbols.");

export const articleValidator = Yup.string().required(infoMessages.requiredField);

const onlySpecialCharactersValidator = (text: string): boolean => {
	const simplifiedText = text.replace(/[^\w ]/g, "");
	return simplifiedText.length === 0;
};

export const onlyNumberValidator = (text: string): boolean => {
	const regex = new RegExp("^[0-9]+$");
	return regex.test(text);
};

export const onlySpaceValidator = (text: string): boolean => {
	const regex = new RegExp("^[ ]+$");
	return regex.test(text);
};

const getStripText = (text: string) => text.replaceAll(/\r\n|\r|\n|\\n\\n/g, "");

export const multipleTextValidators = (text: string, toasterStore: ToasterStore | undefined): boolean => {
	const stripText = getStripText(text);
	if (onlyNumberValidator(stripText)) {
		toasterStore?.showErrorMessage("The field should not contain only numbers");
		return true;
	}
	if (onlySpecialCharactersValidator(stripText)) {
		toasterStore?.showErrorMessage("The field subject should not contain only special characters");
		return true;
	}
	if (onlySpaceValidator(stripText)) {
		toasterStore?.showErrorMessage("The field should not contain only spaces");
		return true;
	}
	return false;
};

export const maxLenghtTextValidator = (
	text: string,
	toasterStore: ToasterStore | undefined,
	maxLength?: number
): boolean => {
	const stripText = getStripText(text);
	const lengthRestriction = maxLength ?? MAX_SIMPLE_TEXT;
	if (stripText.length > lengthRestriction) {
		toasterStore?.showErrorMessage(`The notification text length should not exceed ${lengthRestriction} characters`);
		return true;
	}
	return false;
};

export const maxRecipientsValidator = (
	items: IOptionsItem[],
	toasterStore: ToasterStore | undefined
): boolean => {
	if (size(items) > MAX_EMAILS) {
		toasterStore?.showErrorMessage(
			`The total maximum number of email recipients has been exceeded. Maximum of ${MAX_EMAILS} recipients.`
		);
		return true;
	}
	return false;
};

export const fieldStringValidtor = (maxLength: number = 500) =>
	Yup.string()
		.required(infoMessages.requiredField)
		.min(1, "The field length cannot be less than 1")
		.max(maxLength, `The field length must be less than ${maxLength} symbols.`)
		.matches(/^(?!\.\s)("?|'|`)(\w+|\d+)(?:.*|\n)$/gm, "Please check if the field filled correctly.");
