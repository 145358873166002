import styles from "../styles.module.scss";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { useMemo, useRef, useState } from "react";
import isNil from "lodash/isNil";
import { IChapterRef } from "models/dto/ZoomiLxp/Models/Courses/IChapterRef";
import ChapterTile from "components/partial/chapters/chapter-tile/chapter-tile";
import { getThumbnail } from "helpers/image.helper";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import classNames from "classnames";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import { ReactComponent as CheckIcon } from "assets/icons/ic_check_round.svg";
import { CoursesStore } from "store/courses/courses-store";
export interface IChapterItemModel extends IChapterRef {
	completed?: boolean;
}

interface ChapterItemProps {
	chapter: IChapterRef,
	showTile: boolean,
	isOtherChapter: boolean,
	completed: boolean | undefined,
	isAdaptive: boolean,
	setPreviewOptions: React.Dispatch<React.SetStateAction<
		{
			chapterId: number;
			isShowPreview: boolean;
		}
	>>
	coursesStore: CoursesStore,
	goToChapter: (chapterId: number) => void,
}

export const MyDeskChaptersSection = (props: { courseId: number }) => {
	const { coursesStore } = useAppStore();
	const history = useHistory();
	const { courseId } = props;
	const currentChapterId = coursesStore.curActiveContent.chapter ? coursesStore.curActiveContent.chapter.id : 0;
	const [previewOptions, setPreviewOptions] = useState<{ chapterId: number; isShowPreview: boolean }>({
		chapterId: currentChapterId,
		isShowPreview: false,
	});
	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);
	const isAdaptive = isMobile || isTablet;

	const goToChapter = (chapterId: number) => {
		const chapterItems: ICourseItemModel[] = coursesStore.activeCourseItems.filter((item: ICourseItemModel) =>
			chapterId ? item.chapter?.id === chapterId : !item.chapter
		);
		const firstItem = chapterItems[0];

		if (firstItem) {
			setPreviewOptions({
				chapterId: chapterId,
				isShowPreview: false,
			});

			history.push(
				generatePath(Routes.MyDeskContent, {
					courseId: isNaN(courseId) || isNil(courseId) ? coursesStore.currentCourse.id : courseId,
					courseItemId: firstItem?.courseItemId,
				})
			);
		}
	};

	const getChaptersPreviewList = () => {
		return coursesStore.activeChaptersGroup?.map((chapter: IChapterItemModel) => {
			const showTile = previewOptions.isShowPreview && previewOptions.chapterId === chapter.id;
			const isOtherChapter = currentChapterId !== chapter.id;
			return <ChapterItem
				key={chapter.id}
				chapter={chapter}
				coursesStore={coursesStore}
				showTile={showTile}
				isOtherChapter={isOtherChapter}
				completed={chapter.completed}
				isAdaptive={isAdaptive}
				setPreviewOptions={setPreviewOptions}
				goToChapter={goToChapter}
			/>;
		});
	};

	return !isNil(coursesStore.activeChaptersGroup) && coursesStore.activeChaptersGroup?.length > 1 ? (
		<div className={styles.chapters_list__chapters_section}>{getChaptersPreviewList()}</div>
	) : (
		<></>
	);
};



const ChapterItem = (props : ChapterItemProps) => {
	const {	chapter, showTile, coursesStore, isOtherChapter, completed, isAdaptive, setPreviewOptions, goToChapter} = props;
	const [marginLeft, setMarginLeft] = useState<number | null>(null);
	const elemRef = useRef<HTMLDivElement>(null);

	return (
		<div key={chapter.id}>
			<div className={styles.chapters_list__chapter_wrapper} ref={elemRef}>
				<img
					className={classNames(styles.chapters_list__chapter_preview_image, {
						[styles.chapters_list__inactive_chapter]: isOtherChapter,
						[styles.chapters_list__completed_chapter]: completed,
					})}
					src={getThumbnail(chapter.thumbnail)}
					alt="Chapter preview"
					onMouseEnter={() => {
						const rect = elemRef?.current ? elemRef?.current?.getBoundingClientRect() : null;
						rect?.left && setMarginLeft(rect.left);
						!isAdaptive &&
						setPreviewOptions({
							chapterId: chapter.id,
							isShowPreview: true,
						});

					}
					}
					onMouseLeave={() => {
						setMarginLeft(null);
						setPreviewOptions({
							chapterId: chapter.id,
							isShowPreview: false,
						});
					}
					}
					onClick={() => isOtherChapter && goToChapter(chapter.id)}
				/>
				{completed && <CheckIcon className={styles.chapters_list__check_icon} />}
			</div>
			{showTile && (
				<ChapterTile
					chapter={chapter}
					key={chapter.id}
					onClick={(id) => console.log(id)}
					isActive={true}
					count={coursesStore.activeChaptersItems?.[chapter.id === 0 ? "null" : chapter.id]?.length ?? 0}
					className={styles.chapters_list__chapter_tile}
					marginLeft={marginLeft}
				/>
			)}
		</div>
	);
};

export default observer(MyDeskChaptersSection);
