import { isBadRequest } from "api/utils/api";
import { ExternalIdentityProviderTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalIdentityProviderTypes";
import { showLoginConfirmationModal } from "pages/public/login/login_popup";
import { generatePath, useHistory } from "react-router-dom";
import { Routes } from "routes";
import { useAppStore } from "store";

interface Props {
	externalProvider: ExternalIdentityProviderTypes;
	token: string;
	redirectUri?: string;
}

export function useSocialSignIn() {
	const { accountStore } = useAppStore();
	const history = useHistory();
	const externalSignIn = async (props: Props) => {
		try {
			const response = await accountStore.externalSignIn({
				externalProvider: props.externalProvider,
				accessToken: props.token,
				redirectUri: !!props.redirectUri ? props.redirectUri : "",
				rememberMe: false,
			});
			if (!response.data.data.isPPAccepted) {
				showLoginConfirmationModal(() => {
					accountStore.externalSignIn({
						externalProvider: props.externalProvider,
						accessToken: props.token,
						redirectUri: !!props.redirectUri ? props.redirectUri : "",
						rememberMe: false,
						isPPAccepted: true,
					})
					.then(() => history.push(generatePath(props.redirectUri || Routes.Home)))
					.catch(() => history.push(generatePath(Routes.Home)));
				}, () => history.push(generatePath(Routes.Home)));
			} else {
				history.push(generatePath(props.redirectUri || Routes.Home));
			}
		} catch (err) {
			if (isBadRequest(err)) {
				console.error("Social login error:", err);
			} else {
				throw err;
			}
		}
	};

	return { externalSignIn };
}
